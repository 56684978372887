import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>ITSAS - Leading Design & Development Agency</title>
                <meta name="description" content="ITSolsAndServices - Leading Design & Development Agency" />
                <meta name="og:title" property="og:title" content="ITSolsAndServices - Leading Design & Development Agency"></meta>
                <meta name="twitter:card" content="ITSolsAndServices - Leading Design & Development Agency"></meta>
                <link rel="canonical" href="https://www.itsolsandservices.com/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
