import React from 'react'
import { Link } from 'gatsby' 
import footerMap from "../../assets/images/footer-map.png" 
import logo from '../../assets/images/star-icon.png'

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="footer-area bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-footer-widget">
                            <h3>IT Sols And Services</h3> 

                                <p>Leading Design & Development Agency</p>
                                <p>Creating better digital products, apps, websites with exceptional user experiences</p>

                                <ul className="social-link">
                                    <li>
                                        <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-facebook'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-twitter'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-instagram'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            {/* <div className="single-footer-widget">
                                <h3>Address</h3>

                                <ul className="footer-contact-info">
                                    <li>
                                        <i className='bx bx-map'></i>
                                    175 5th Ave, New York, NY 10010, <br /> United States
                                </li>
                                    <li>
                                        <i className='bx bx-phone-call'></i>
                                        <a href="tel:+44587154756">+1 (123) 456 7890</a>
                                    </li>
                                    <li>
                                        <i className='bx bx-envelope'></i>
                                        <a href="mailto:info@itsolsandservices.com">hinfo@itsolsandservices.com</a>
                                    </li> 
                                </ul>
                            </div> */}
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Address</h3>

                                <ul className="footer-contact-info">
                                    <li>
                                        <i className='bx bx-map'></i>
                                Burroughs Drive, Dartford, Kent <br />  DA1 5TX <br /> UK
                                </li>
                                    <li>
                                        <i className='bx bx-phone-call'></i>
                                        <a href="tel:+44587154756">+44 7490494512</a>
                                    </li>
                                    <li>
                                        <i className='bx bx-envelope'></i>
                                        <a href="mailto:info@itsolsandservices.com">info@itsolsandservices.com</a>
                                    </li> 
                                </ul>
                            </div>
                        </div>
                
                    </div>
                </div>

                <div className="footer-map">
                    <img src={footerMap} alt="footer-logo" />
                </div>
            </footer>
            <footer className="footer-area bg-color">

                <div className="footer-bottom-area shadow">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>Copyright @{currentYear}</p>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li>
                                        <Link to="/privacy-policy">
                                            Privacy Policy
                                    </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-of-service">
                                            Terms & Conditions
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;